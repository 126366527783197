/* eslint-disable eqeqeq */

import { createSlice } from "@reduxjs/toolkit";
import {
  clipWidth,
  copy,
  getVideoId,
  parseClip,
  sec2width,
  setCropFramePosition,
} from "../js/utils";
import { Clipper } from "../js/Clipper";

// -1 (unstarted)
// 0 (ended)
// 1 (playing)
// 2 (paused)
// 3 (buffering)
// 5 (video cued)
const initialState = {
  ytPlayer: null,

  progressBar: null,
  clipRange: null,
  redProgress: null,

  ratioBox: null,
  frameDiv: null,

  clipRangeMouseDown: false,
  clipRangeDragged: false,
  startInput: null,
  endInput: null,
  sVideo: {
    id: null,
    url: null,
    loaded: false,
    mute: false,
    duration: null,
  },
  sClip: {
    startTime: null,
    elapsedTime: 0,
    endTime: null,
  },
  sCropClips: [],
  sCropFrame: {
    isOn: false,
    x1: 0,
    x2: 31.64, // default 9:16. width %
    ratio: 3,
  },
  ratiosList: ["16/9", "1/1", "3/4", "9/16"],
  ratioWidths: [100, 56, 42, 31], // floor-ed widths for each ratio

  loadingState: 0, // 0-to-N:  'none', 'merge', 'download'

  clipOptions: {
    videoLanguage: "en",
    isSubsNeeded: false,
    dubLanguage: false,
    subStyle: "style_1",
  },
};

function updateClipRangeHelper(store, clipStart, clipEnd) {
  const { duration } = store.sVideo;
  const { clipRange, redProgress } = store;

  clipStart ??= store.sClip.startTime;
  clipEnd ??= store.sClip.endTime;

  store.sClip.startTime = Math.min(Math.floor(duration - 0.01), clipStart);
  store.sClip.endTime = Math.min(Math.floor(duration), clipEnd);

  store.ytPlayer.seekTo(clipStart);

  const fromLeft = sec2width(clipStart, 0, duration);

  clipRange.style.left = fromLeft + "%";
  clipRange.style.width = sec2width(clipEnd - clipStart, 0, duration) + "%";
  redProgress.style.width = 0;

  clipRange.setAttribute("data-x", fromLeft);
}

export const counterSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    set(store, { payload }) {
      for (const key in payload) {
        store[key] = payload[key];
      }
    },

    setActiveVideo(store, { payload }) {
      let { ytPlayer, sVideo } = store;
      sVideo.url = payload.url;
      sVideo.id = payload.id || getVideoId(payload.url);

      ytPlayer?.loadVideoById(sVideo.id);

      store.sCropClips = JSON.parse(localStorage.getItem(sVideo.id) || "[]");
    },

    resetClipInputs(store) {
      store.sClip.startTime = "";
      store.sClip.endTime = "";
    },

    setYTPlayer(store, { payload }) {
      store.ytPlayer = payload;
    },

    setVideoStates(store, { payload }) {
      for (const key in payload) {
        store.sVideo[key] = payload[key];
      }
    },

    setVideoInfo(store, { payload }) {
      const { sVideo, sClip, ytPlayer } = store;

      sVideo.duration = ytPlayer.getDuration();

      if (!store.sVideo.loaded) {
        sClip.startTime = 0;
        sClip.endTime = sVideo.duration;

        store.sVideo.loaded = true;
      }
    },

    updateClipRange(store, { payload }) {
      let { clipStart, clipEnd } = payload;
      updateClipRangeHelper(store, clipStart, clipEnd);
    },

    updateCropClips(store, { payload }) {
      const { sClip, sCropFrame, sCropClips, sVideo } = store;

      // if (sCropClips.length >= 5) return alert("Max 5 clips per video");

      const range = new Clipper(
        sClip.startTime,
        sClip.endTime,
        sCropFrame.x1,
        sCropFrame.x2
      );

      sCropClips.push(range);

      localStorage.setItem(sVideo.id, JSON.stringify(sCropClips));
    },

    deleteCropClip(store, { payload: index }) {
      const { sCropClips, sVideo } = store;

      sCropClips.splice(index, 1);

      localStorage.setItem(sVideo.id, JSON.stringify(sCropClips));
    },

    setCropFrame(store, { payload: frame }) {
      const { sCropFrame } = store;

      for (const key in frame) {
        sCropFrame[key] = frame[key];
      }
    },

    setClipToEdit(store, { payload: index }) {
      const { start, end, x1, x2 } = parseClip(copy(store.sCropClips[index]));

      store.sClip.startTime = start;
      store.sClip.endTime = end;
      store.sClip.elapsedTime = 0;

      store.sCropFrame.x1 = x1;
      store.sCropFrame.x2 = x2;

      updateClipRangeHelper(store, start, end);

      store.sCropFrame.ratio = store.ratioWidths.indexOf(clipWidth(store.sCropClips[index]));

      setCropFramePosition(x1, x2);
    },
  },
});

export const {
  setActiveVideo,
  resetClipInputs,
  setYTPlayer,
  setVideoStates,
  setVideoInfo,
  set,
  updateClipRange,
  updateCropClips,
  deleteCropClip,
  setCropFrame,
  setClipToEdit,
} = counterSlice.actions;

export default counterSlice.reducer;
