/* eslint-disable eqeqeq */
import store from "../redux/store";
import {
  set,
  setCropFrame,
  setVideoInfo,
  setVideoStates,
  setYTPlayer,
  updateClipRange,
} from "../redux/appSlice";
import interact from "interactjs";

let appState;
store.subscribe(() => (appState = store.getState()));

const apiUrl = "https://api.clipcut.tech"

export function stopEventBubbling(event) {
  event.stopPropagation();
}

export function validateVideoURL(url) {
  const ytRegex =
    /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?.*v=|embed\/|v\/|u\/\w\/|playlist\?.*list=)|youtu\.be\/)([\w-]{11})(?:[?&].*|)$/g;
  return ytRegex.exec(url) !== null;
}

export function getVideoId(url) {
  if (!url) return;
  const urlIndex = url.indexOf("http");

  if (urlIndex === -1) return toast("Invalid YouTube URL", 5, 1);

  url = url.slice(urlIndex);
  url = decodeURIComponent(url);

  if (!validateVideoURL(url)) return console.error("Invalid YT URL");

  url = new URL(url);

  if (url.hostname === "youtu.be") {
    return url.pathname.slice(1);
  }

  if (url.hostname.endsWith("youtube.com")) {
    if (url.pathname === "/watch") {
      const params = new URLSearchParams(url.search);
      return params.get("v");
    }

    if (url.pathname.startsWith("/embed/")) {
      return url.pathname.split("/")[2];
    }

    if (url.pathname.startsWith("/v/")) {
      return url.pathname.split("/")[2];
    }
  }
  return null;
}

// Start : Conversions
export function sec2width(sec, start, end) {
  const { startTime, elapsedTime, endTime } = appState?.sClip ?? {};

  start ??= startTime || 0;
  sec ??= elapsedTime || 0;
  end ??= endTime || 0;

  return ((100 * (sec - start)) / (end - start)).toFixed(2);
}

export function secToMMSS(value) {
  let format = "XX:_YY:_ZZ";

  const hours = Math.floor(value / 3600);
  const minutes = Math.floor((value % 3600) / 60);
  const seconds = Math.round(value % 60);

  format = format.split("_");

  let formattedString = "";

  if (hours) formattedString += format[0].replace(/x+/i, hours);

  formattedString += format[1].replace(/y+/i, minutes);

  formattedString += seconds < 10 ? 0 : "";
  formattedString += format[2].replace(/z+/i, seconds);

  return formattedString;
}

export function px2SecInRange(px, parent, start, end) {
  start ??= appState.sClip.startTime;
  end ??= appState.sClip.endTime;

  const { left, right } = parent.getBoundingClientRect();

  const pxPercent = (100 * (px - left)) / (right - left);

  return (start + (pxPercent * (end - start)) / 100).toFixed(2);
}

function pxInPercent(value, parent) {
  parent ??= appState.progressBar;

  const { width } = parent.getBoundingClientRect();

  return (value * 100) / width;
}

function percentInPX(percentValue, parent) {
  parent ??= appState.progressBar;

  const { width } = parent.getBoundingClientRect();
  
  return (percentValue * width) / 100;
}
// End : Conversions

// Start : Video Play
export function playVideo() {
  appState.ytPlayer.playVideo();
}

export function pauseVideo() {
  appState.ytPlayer.pauseVideo();
}

export function stopVideo() {
  appState.ytPlayer.stopVideo();
}

export function playPauseVideo(e) {
  e.preventDefault();
  
  if (appState.ytPlayer.getPlayerState() == 1) pauseVideo();
  else playVideo();
}
// End : Video Play

/*  */

// Start : YT API
export function integrateYTApi() {
  var tag = document.createElement("script");
  tag.src = "https://www.youtube.com/iframe_api";
  var firstScriptTag = document.getElementsByTagName("script")[0];
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
}

window.onYouTubeIframeAPIReady = function () {
  const player = new window.YT.Player("player", {
    height: "380",
    width: "640",
    videoId: appState?.sVideo.id || "WhWc3b3KhnY",
    playerVars: { start: 0, autoplay: 1 * 0, controls: 1, rel: 0 },
    events: {
      onReady: onPlayerReady,
      onStateChange: onPlayerStateChange,
      onAutoplayBlocked: (e) => console.log("autoplay blocked"),
      onApiChange: onPlayerReady,
    },
  });

  store.dispatch(setYTPlayer(player));
};

function onPlayerReady(event) {
  store.dispatch(setVideoInfo());

  updateElapsedTime();
  updateValidation();
  // setQualityLevels();

  // toast("ready", 3, 1);
}

function onPlayerStateChange(event) {
  // toast("stateChange", 3);

  if (event.data == 0) {
    updateElapsedTime(appState.sVideo.duration);
  }
  if (event.data == -1) {
    updateElapsedTime(0);
  }
  if (event.data == 1) {
    // console.log(appState.ytPlayer.playerInfo);
    store.dispatch(setVideoStates({
      mute: true
    }));
    store.dispatch(setVideoInfo());
    updateProgress();
  }
}
// End : YT API

export function playControlHover(e){
  const { left: leftpx, right: rightpx } = $('.play_range').getBoundingClientRect();

  const vidDuration = appState.sVideo.duration;
  const progressBar = appState.progressBar;

  const clipStart = px2SecInRange(leftpx, progressBar, 0, vidDuration);

  const clipEnd = px2SecInRange(rightpx, progressBar, 0, vidDuration);

  $('.resize_left span').innerHTML = secToMMSS(clipStart);
  $('.resize_right span').innerHTML = secToMMSS(clipEnd);
}

export function toast(msg, t = 3, error = false) {
  if ($(".toast")) $(".toast").remove();

  const toast = document.createElement("div");
  toast.className = "toast " + (error && "error");

  toast.innerHTML = `<p>${msg}</p>`;
  $("body").appendChild(toast);

  setTimeout(() => {
    toast.remove();
  }, t * 1000);

  toast.onclick = () => toast.remove();
}

export function $(selector) {
  return document.querySelector(selector);
}

export function makeControlsDraggable() {
  interact(appState.clipRange)
    .resizable({
      edges: {
        left: ".resize_left",
        right: ".resize_right",
        bottom: false,
        top: false,
      },

      listeners: {
        move(event) {
          const target = event.target;

          let x = +target.getAttribute("data-x") || 0;

          target.style.width = pxInPercent(event.rect.width) + "%";

          x += pxInPercent(event.deltaRect.left);

          target.style.left = x + "%";

          target.setAttribute("data-x", x);
          playControlHover(event);
        },
        end(e) {
          const { left: leftpx, right: rightpx } =
            e.target.getBoundingClientRect();

          const vidDuration = appState.sVideo.duration;
          const progressBar = appState.progressBar;

          const clipStart = px2SecInRange(leftpx, progressBar, 0, vidDuration);

          const clipEnd = px2SecInRange(rightpx, progressBar, 0, vidDuration);

          store.dispatch(updateClipRange({ clipStart, clipEnd }));
          store.dispatch(set({ sClip: { ...appState.sClip, elapsedTime: 0 } }));
          updateValidation(clipStart, clipEnd);
        },
      },

      modifiers: [
        interact.modifiers.restrictEdges({
          outer: "parent",
        }),

        interact.modifiers.restrictSize({
          min: { width: 0 },
        }),
      ],
    })
    .draggable({
      listeners: {
        move(event) {
          const target = event.target;
          const t = target;

          const x = (+t.getAttribute("data-x") || 0) + pxInPercent(event.dx);

          target.style.left = x + "%";

          target.classList.add("moving");

          target.setAttribute("data-x", x);

          store.dispatch(set({ clipRangeDragged: true }));
          playControlHover(event);
        },
        end(event) {
          const target = event.target;
          const t = target;

          target.classList.remove("moving");

          const videoDuration = appState.sVideo.duration;
          const progressBar = appState.progressBar;

          const { right: rightpx, left: leftpx } = t.getBoundingClientRect();

          const clipStart = +px2SecInRange(
            leftpx,
            progressBar,
            0,
            videoDuration
          );

          const clipEnd = +px2SecInRange(
            rightpx,
            progressBar,
            0,
            videoDuration
          );

          store.dispatch(updateClipRange({ clipStart, clipEnd }));
          store.dispatch(set({ sClip: { ...appState.sClip, elapsedTime: 0 } }));
          updateValidation(clipStart, clipEnd);
          requestIdleCallback(() => store.dispatch(set({ clipRangeDragged: false })));
        },
      },
      inertia: true,
      modifiers: [
        interact.modifiers.restrictRect({
          restriction: "parent",
        }),
      ],
    });
}

export function makeRatioBoxDraggable() {
  const { ratioBox, frameDiv } = appState;

  interact(ratioBox)
  .resizable({
    // resizable blocked
    edges: { left: false, right: false, bottom: false, top: false },
    /*
      margin: 5,
      listeners: {
        move(event) {
          var target = event.target;
          var x = +target.getAttribute("data-x") || 0;

          target.style.width = event.rect.width + "px";

          x += event.deltaRect.left;

          target.style.left = x + "px";

          target.setAttribute("data-x", x);

          const { x: frameLeft, right: frameRight } = frameDiv.getBoundingClientRect();
          const { x: cropLeft, right: cropRight } = target.getBoundingClientRect();

          const x1 = ((100 * (cropLeft - frameLeft)) / (frameRight - frameLeft)).toFixed(2);
          const x2 = ((100 * (cropRight - frameLeft)) / (frameRight - frameLeft)).toFixed(2);

          // $(".ratio_output .x1").innerHTML = x1;
          // $(".ratio_output .x2").innerHTML = x2;
        },
      },
      modifiers: [
        interact.modifiers.restrictEdges({
          outer: "parent",
        }),

        interact.modifiers.restrictSize({
          min: { width: 100 },
        }),
      ],
    */
  })
  .draggable({
    listeners: {
      move(event) {
        var target = event.target;
        var x = (+target.getAttribute("data-x") || 0) + event.dx;

        target.style.left = x + "px";

        target.setAttribute("data-x", x);
        
        const { x: frameLeft, right: frameRight } = frameDiv.getBoundingClientRect();
        const { x: cropLeft, right: cropRight } = target.getBoundingClientRect();

        const x1 = ((100 * (cropLeft - frameLeft)) / (frameRight - frameLeft)).toFixed(2);
        const x2 = ((100 * (cropRight - frameLeft)) / (frameRight - frameLeft)).toFixed(2);

        store.dispatch(setCropFrame({ x1, x2 }));
      },
    },
    inertia: true,
    modifiers: [
      interact.modifiers.restrictRect({
        restriction: "parent",
      }),
    ],
  });
}

function updateProgress() {
  requestAnimationFrame(() => {
    const {
      ytPlayer,
      sClip: { startTime, endTime },
    } = appState;

    const currTime = ytPlayer.getCurrentTime();

    if (!isBaseClear()) return;

    // console.log(startTime, endTime, appState.sVideo.duration);


    if (currTime >= endTime || currTime < startTime) {
      ytPlayer.seekTo(startTime);
      updateElapsedTime(startTime);
    } else {
      updateElapsedTime(currTime);
    }

    if (ytPlayer.getPlayerState() === 1) updateProgress();
  });
}

export function updateValidation(start, end) {
  const { startInput, endInput } = appState;

  startInput?.setAttribute("min", start);
  startInput?.setAttribute("max", end - 0.01);

  endInput?.setAttribute("min", +start + 0.01);
  endInput?.setAttribute("max", end);
}

function updateElapsedTime(currTime = 0) {
  store.dispatch(set({ sClip: { ...appState.sClip, elapsedTime: currTime } }));
}

function isBaseClear() {
  const { startTime, endTime } = appState.sClip;
  const { duration } = appState.sVideo;

  if (!(endTime > startTime)) {
    pauseVideo();
    return false;
  }

  return true;
}


export function handleKeyDown(e) {
  const { ytPlayer, sClip } = appState;

  const keyMap = {
    " ": playPauseVideo,

    ArrowRight() {
      ytPlayer.seekTo(Math.min(ytPlayer.getCurrentTime() + 5, sClip.endTime));
    },

    ArrowLeft() {
      ytPlayer.seekTo(Math.max(ytPlayer.getCurrentTime() - 5, sClip.startTime));
    },

    m: () => ytPlayer[ytPlayer.isMuted() ? "unMute" : "mute"](),
    M: () => ytPlayer[ytPlayer.isMuted() ? "unMute" : "mute"](),
  };

  keyMap[e.key]?.(e);
}

export function handleMouseUp(e){
  store.dispatch(set({clipRangeMouseDown: false, clipRangeDragged: false}));
}

export async function initiateClipper() {
  const { ytPlayer, sCropClips, clipOptions } = appState;
  const { videoLanguage, isSubsNeeded, dubLanguage, subStyle } = clipOptions;

  const data = {
    youtube_url: ytPlayer.getVideoUrl(),
    time_ratio_ranges: {
      time_ranges: sCropClips,
    },
    is_sub_needed: isSubsNeeded,
    video_lang: videoLanguage,
    sub_style: isSubsNeeded && subStyle,
    translate_subtitles: {
      is_translation_needed: !!dubLanguage,
      target_language: dubLanguage
    }
  };

  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json; charset=utf-8");

  try {
    const response = await fetch(`${apiUrl}/process_video`, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(data),
    });
    
    const { task_id } = await response.json();
    if (!task_id) {
      alert("Error While Downloading Video. Try again!");
      store.dispatch(set({ loadingState: 0 }));
    }

    const clip_id = await pollForClipId(task_id);

    if (clip_id) {
      downloadVideo(clip_id);
    } else {
      toast("Failed to retrieve clip_id", 6, 1);
      store.dispatch(set({ loadingState: 0 }));
    }
  } catch (error) {
    console.error(error);
    toast("Error While Merging Video", 6, 1);
    store.dispatch(set({ loadingState: 0 }));
  }
}

async function downloadVideo(clip_id) {
  store.dispatch(set({ loadingState: 2 }));

  try {
    const response = await fetch(`${apiUrl}/get_video?clip_id=${clip_id}`, {
      method: "GET",
    });

    const blob = await response.blob();
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = `video_${appState.sVideo.id}.mp4`;
    document.body.appendChild(a);
    a.click();
    URL.revokeObjectURL(url);
    a.remove();

    store.dispatch(set({ loadingState: 0 }));
  } catch (error) {
    console.error(error);
    alert("Error While Downloading Video", 6, 1);
    store.dispatch(set({ loadingState: 0 }));
  }
}

async function pollForClipId(task_id) {
  const pollInterval = 5000;

  while(true) {
    try {
      const response = await fetch(`https://api.clipcut.tech/video_status/${task_id}`, {
        method: "GET",
        redirect: "follow"
      });

      const result = await response.json();
      if (result.state == "SUCCESS") {
        return result.clip_id;
      }
      else if (result.state == "PENDING") {
      }
    } catch (error) {
      toast('Error in downloading',5);
      console.error(error);
    }

    // Wait before the next attempt
    await new Promise(resolve => setTimeout(resolve, pollInterval));
  }
}

export function parseClip(clip){
  if(typeof clip == 'string'){
    clip = JSON.parse(clip);
  }

  let cropBox = clip[2];
  cropBox = cropBox.replaceAll(' ', '').split(',');

  return {
    start: clip[0],
    end: clip[1],
    x1: cropBox[0],
    x2: cropBox[2]
  };
}

export function clipWidth(clip){
  const {x1, x2} = parseClip(clip);
  return Math.floor(x2 - x1);
}

export function clipRatio(clip){
  return  appState.ratiosList[appState.ratioWidths.indexOf(clipWidth(clip))]?.replace('/', ':');
}

export function copy(variable){
  return JSON.parse(JSON.stringify(variable));
}

export function setCropFramePosition( x1, x2 ){
  const { ratioBox, frameDiv } = appState;

  x1 = percentInPX(x1, frameDiv);
  ratioBox.style.left =  x1 + "px";
  ratioBox.setAttribute("data-x", x1);

}

export function mouseSeekMove(e) {
  // BUG: This function does't seem to work
  stopEventBubbling(e)
  if (!appState.clipRangeMouseDown || appState.clipRangeDragged) return;

  if(!e.target.classList.contains('play_range')) return;

  const seekTime = px2SecInRange(e.clientX, e.target);
  appState.ytPlayer.seekTo(seekTime);
  playVideo();
}

export function cdn(_, path){
  return `https://cdn.clipcut.tech/${path}`
}