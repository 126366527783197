import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { $, makeControlsDraggable, makeRatioBoxDraggable, mouseSeekMove, playControlHover, sec2width, secToMMSS, stopEventBubbling } from "../js/utils";
import { set } from "../redux/appSlice";
import TimeInput from "./components/TimeInput";
import CropFrameInput from "./components/CropFrameInput";

const VideoViewer = () => {
  const dispatch = useDispatch();
  const { ytPlayer, sVideo, sClip, sCropFrame, ratiosList } = useSelector((state) => state);

  // eslint-disable-next-line 
  const [videoDuration, setVideoDuration] = useState("");

  const aspectRatio = ratiosList[sCropFrame.ratio];
  const showFrame = sCropFrame.isOn;

  if (sVideo.loaded) {
    const { width, height } = $(".iframe_container").getBoundingClientRect();
    ytPlayer.setSize(width, height);
  }

  function handleProgressMouseDown(){
    dispatch(set({clipRangeMouseDown: true}));
  }
  function handleProgressMouseUp(e){
    mouseSeekMove(e);
  }

  useEffect(() => {
    setVideoDuration(secToMMSS(sVideo.duration));
  }, [sVideo.duration]);

  useEffect(() => {
    if(sVideo.mute){
      console.log('muting')
      ytPlayer.mute();
    }
    // eslint-disable-next-line
  }, [sVideo.mute]);

  useEffect(() => {
    const controlRefs = {
      progressBar: $(".progress_bar"),
      clipRange: $(".play_range"),
      redProgress: $(".progress"),
      ratioBox: $(".box_ratio"),
      frameDiv: $(".frame_crop"),
    };

    dispatch(set(controlRefs));


    makeControlsDraggable()
    makeRatioBoxDraggable()
    // eslint-disable-next-line
  }, []);

  return (
    <div className="video_container">
      <div className="iframe_container">
        <div id="player" className="embed-responsive-item"></div>

        <div className={`frame_crop ${showFrame && 'active'}`}>
          <div className="box_ratio" style={{aspectRatio: aspectRatio}}></div>
        </div>
      </div>

      <div className="playback_controls">
        <div className="progress_bar">
          <div  className="play_range" onMouseDown={handleProgressMouseDown} onMouseUp={handleProgressMouseUp}>
            <div className="progress" style={{width: sec2width()+'%'}}></div>
            <div
              className="play_range_controls"
              onMouseDown={stopEventBubbling}
              onMouseUp={stopEventBubbling}
            >
              <div className="resize_left" onMouseOver={playControlHover}>
                <span className="tip_timings"></span>
              </div>
              <div className="resize_right" onMouseOver={playControlHover}>
                <span className="tip_timings"></span>
              </div>
            </div>
          </div>
        </div>
        
        <div className="time_display">
          <span className="time_current">{secToMMSS(sClip.elapsedTime)}</span>
          <span>/</span>
          <span className="time_duration">{secToMMSS(sClip.endTime)}</span>
        </div>

        <div className="my-4 flex flex-col gap-6">
          <TimeInput />
          <CropFrameInput />
        </div>
      </div>

      {/* <div className="settings">
        <img width="30" height="30" src="https://img.icons8.com/ios/50/settings--v1.png" alt="settings--v1"/>
      </div> */}
    </div>
  );
};

export default VideoViewer;


