/* eslint-disable eqeqeq */

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCropClip,
  set,
  setClipToEdit,
  setCropFrame,
} from "../redux/appSlice";
import { clipRatio, clipWidth, initiateClipper, parseClip, secToMMSS, toast } from "../js/utils";
import ClipDownloaderModal from "../pages/components/ClipDownloaderModal";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import availableLanguages from "../js/languages";
import { MdDelete } from "react-icons/md";
import { RiDraggable } from "react-icons/ri";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const SidePanel = () => {
  const dispatch = useDispatch();
  const { sCropClips, loadingState, clipOptions } = useSelector(
    (state) => state
  );

  const { videoLanguage, isSubsNeeded, dubLanguage, subStyle } = clipOptions;

  function setOption(key, value) {
    dispatch(set({ clipOptions: { ...clipOptions, [key]: value } }));
  }

  const selectInputStyle = {
    backgroundColor: "#131526",
    fontSize: "14px",
    color: "#f3f3f3",
    ".MuiOutlinedInput-notchedOutline": {
      borderColor: "#f3f3f3", // outline color
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#f3f3f3", // Outline color on hover
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#f3f3f3", // outline color when focused
    },
    ".MuiSvgIcon-root": {
      color: "#f3f3f3", // down arrow color
    },
    "&:hover .MuiSvgIcon-root": {
      color: "#f3f3f3", // Down arrow color on hover
    },
  };

  const selectlabelStyle = {
    color: "#f3f3f3",
    "&.Mui-focused": {
      color: "#f3f3f3",
    },
    fontSize: "16px",
  };

  function mergeAndDownload() {
    const width = clipWidth(sCropClips[0]);
    if(sCropClips.every(clip => clipWidth(clip) == width)){
      initiateClipper();
      dispatch(set({ loadingState: 1 }));
      return;
    }

    else {
      toast('All clips must have same ratios', 6, 1);
    }
    
  }

  function makeClipEdit(index) {
    dispatch(setCropFrame({ isOn: true }));
    requestIdleCallback(() => dispatch(setClipToEdit(index)));
  }

  function clipTitle(i, clip) {
    const { start, end } = parseClip(clip);

    let clipName = (
      <>
       Clip <span>{secToMMSS(start)}</span> to <span className="mr-4">{secToMMSS(end)}</span> Ratio: {clipRatio(clip)}
      </>
    );

    return clipName;
  }

  function reorder(list, startIndex, endIndex) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  }

  function onDragEnd(result) {
    if (!result.destination) return;

    const reorderedItems = reorder(
      sCropClips,
      result.source.index,
      result.destination.index
    );

    dispatch(set({ sCropClips: reorderedItems }));
  }

  return (
    <>
      <div className="side_panel flex flex-col gap-4">
        <div>
          <span>Clip & Crop</span>
        </div>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="clips">
            {(provided, snapshot) => (
              <div
                className="clips_container flex flex-col gap-2 overflow-y-auto"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {!sCropClips?.length &&
                  [50, 30, 30].map((el, i) => (
                    <div key={i} className={`clip_item_skeleten py-1.5 px-3 bg-[#35374a] flex-1 rounded-md flex select-none opacity-${el}`}></div>
                  ))}

                {sCropClips.map((el, i) => (
                  <Draggable key={i} draggableId={`clip-${i}`} index={i}>
                    {(provided) => (
                      <div
                        className="flex items-strech gap-2"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                      >
                        <div
                          className={`clip_item py-1.5 px-3 bg-[#35374a] flex-1 rounded-md flex select-none`}
                        >
                          <div
                            className="mr-1 flex items-center cursor-grab"
                            {...provided.dragHandleProps}
                          >
                            <RiDraggable />
                          </div>
                          <Tooltip className="mr-2" title="Preview" placement="right" sx={{pointerEvents: 'none'}}>
                            <button
                              className="preview_btn"
                              onClick={() => makeClipEdit(i)}
                            >
                              ▶
                            </button>
                          </Tooltip>
                          {/* <p>{el[2]}</p> */}
                          <p>{clipTitle(i, el)}</p>
                          <Tooltip title="Delete" placement="left" sx={{pointerEvents: 'none'}}>
                            <button
                              className="delete_btn text-red-400 rounded-md select-none"
                              onClick={() => {
                                dispatch(deleteCropClip(i));
                              }}
                            >
                              <MdDelete />
                            </button>
                          </Tooltip>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        {!sCropClips?.length && (
          <div className="clip_items_skeleten">
            <div className={`py-1.5 px-3 bg-[#000000] rounded-md select-none text-center opacity-30`}>
              <span className="font-black text-2xl">+</span> Add clips to download
            </div>
          </div>
        )}

        <div className="video_options">
          <div className="setting_option is_subs">
            <FormControl
              sx={{ width: "100%", backgroundColor: "#0000" }}
              size="small"
            >
              <InputLabel id="is_subs-label" sx={selectlabelStyle}>
                Show Subtitles
              </InputLabel>
              <Select
                labelId="is_subs-label"
                id="is_subs"
                value={isSubsNeeded}
                label="Show Subtitles"
                onChange={(event) =>
                  setOption("isSubsNeeded", event.target.value)
                }
                sx={selectInputStyle}
              >
                <MenuItem value={false}>No Subtitles</MenuItem>
                <MenuItem value={true}>Yes</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div
            className={
              "setting_option video_lang"
            }
          >
            <FormControl
              sx={{ width: "100%", backgroundColor: "#0000" }}
              size="small"
            >
              <InputLabel id="video_lang-label" sx={selectlabelStyle}>
                Video Language
              </InputLabel>
              <Select
                labelId="video_lang-label"
                id="video_language"
                value={videoLanguage}
                label="Video Language"
                onChange={(event) =>
                  setOption("videoLanguage", event.target.value)
                }
                sx={selectInputStyle}
              >
                {availableLanguages.map((language) => {
                  return (
                    <MenuItem key={language.code} value={language.code}>
                      {language.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>

          <div
            className={
              "setting_option sub_styles " + (isSubsNeeded || "disabled")
            }
          >
            <FormControl
              sx={{ width: "100%", backgroundColor: "#0000" }}
              size="small"
            >
              <InputLabel id="sub_style-label" sx={selectlabelStyle}>
                Subtitle Style
              </InputLabel>
              <Select
                labelId="sub_style-label"
                id="sub_style"
                value={subStyle}
                label="Subtitle Styles"
                onChange={(event) => setOption("subStyle", event.target.value)}
                sx={selectInputStyle}
              >
                <MenuItem value={"style_1"}>Dark Background</MenuItem>
                <MenuItem value={"style_2"}>Text Outline</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div
            className={"setting_option dubbing " + (isSubsNeeded || "disabled")}
          >
            <FormControl
              sx={{ width: "100%", backgroundColor: "#0000" }}
              size="small"
            >
              <InputLabel id="dubbing-label" sx={selectlabelStyle}>
                Subtitles Language
              </InputLabel>
              <Select
                labelId="dubbing-label"
                id="dubbing"
                value={dubLanguage}
                label="Subtitles Language"
                onChange={(event) =>
                  setOption("dubLanguage", event.target.value)
                }
                sx={selectInputStyle}
              >
                <MenuItem value={false}>No Dubbing</MenuItem>
                {availableLanguages.map((language) => {
                  return (
                    <MenuItem key={language.code} value={language.code}>
                      {language.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        </div>

        <div className="btn action_btns w-full flex flex-row gap-2">
          <button
            className={`download w-full p-3 rounded-md ${
              [1, 2].includes(loadingState) && "disabled loading"
            }`}
            onClick={mergeAndDownload}
          >
            {/* <div class="loader"></div> */}
            {sCropClips.length > 1 ? "Merge & " : ""}Download Clip{sCropClips.length > 1 && 's'}
          </button>

          {/* <button className="download w-full p-3 rounded-md" onClick={mergeAndDownload}>
            Download separately
          </button> */}
        </div>
      </div>

      {loadingState>0 ? <ClipDownloaderModal /> : <></>}
    </>
  );
};

export default SidePanel;
